<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1200"
  >
    <v-card >
      <v-toolbar
        class="block"
        dark
        color="white"
        elevation="0"
      >
        <v-toolbar-title class="black--text">
          <h4 class="font-weight-bold">{{$_strings.order.CHANGE_ROUTE}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            color="black"
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-expansion-panels ref="panels" v-model="panel" accordion style="overflow: auto">
        <v-expansion-panel
          v-for="(route,indexRoute) in form.routes.length"
          :key="indexRoute"
        >
          <v-expansion-panel-header
            class="grey lighten-5"
          >
            <h4>Kargo {{indexRoute + 1}}</h4>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="red"
              max-width="150"
              class="white--text"
              :disabled="disabledDeleteRoute(indexRoute, form.routes[indexRoute].shipmentsCargoId)"
              @click.prevent.stop="handleDeleteRoute(indexRoute, form.routes[indexRoute].shipmentsCargoId)"
              :loading="isLoadingDeleteRoute.includes(indexRoute)"
            >
              {{$_strings.order.DELETE_ROUTE}}
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form
              ref="form"
              lazy-validation
              :disabled="disabledForm(form.routes[indexRoute].shipmentsCargoId)"
            >
              <form-route
                :pickupDate="pickupDate"
                :isBackdate="isBackdate"
                :item="item"
                :form="form.routes[indexRoute]"
                :locationFilterItems="locationFilterItems"
                :transportTypesFilterItems="transportTypesFilterItems"
                :itemsLocation="itemsLocation"
                :itemsVehicle="itemsVehicle"
                :disabled="disabledForm(form.routes[indexRoute].shipmentsCargoId)"
                :canDeleteRoute="disabledDeleteRoute(indexRoute, form.routes[indexRoute].shipmentsCargoId)"
                @updateAutoCompleteItems="updateAutoCompleteItems"
              />
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <v-btn
          @click="addRoute"
          outlined
          small
          color="primary"
        >
          {{$_strings.order.ADD_RUTE}}
        </v-btn>
        <v-btn
          @click="dialog = false"
          class="ml-2 white--text"
          small
          color="red"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          :disabled="isLoading"
          @click="submitRoute"
          class="ml-2"
          small
          color="primary"
          :loading="isLoading"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { filterDuplicateValues, getDateTimeTz } from '@/helper/commonHelpers';
import FormRoute from './FormRoute.vue';

export default {
  components: {
    FormRoute,
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.panel = 0;
        this.isLoadingDeleteRoute = [];
        this.form = {
          dateTimeTz: this.getDateTimeTz(),
          shipmentsGroupId: '',
          routes: [
            {
              shipmentsCargoId: null,
              pickupDate: '',
              serviceType: '',
              shipmentId: [],
              transportTypeId: '',
              originLocationId: '',
              multiPick: [],
              multiDrop: [],
              destinationLocationId: '',
            },
          ],
        };
        if (this.reFecthData) {
          this.$emit('refecthData');
          this.reFecthData = false;
        }
      }
    },
    routeData(newVal) {
      if (newVal.length) {
        // SET FORM
        this.form.routes = newVal.map((item) => ({
          shipmentsCargoId: item.shipmentsCargoId,
          pickupDate: item.pickupDate,
          serviceType: item.serviceType,
          shipmentId: item.shipments ? item.shipments.map((shipment) => shipment.shipmentsId) : [],
          transportTypeId: item.transportTypeId,
          originLocationId: item.originLocationId,
          multiPick: item.multiPick ? item.multiPick.map((pick, index) => ({ locationId: pick.locationId, sequenceNo: index + 1 })) : [],
          multiDrop: item.multiDrop ? item.multiDrop.map((drop, index) => ({ locationId: drop.locationId, sequenceNo: index + 1 })) : [],
          destinationLocationId: item.destinationLocationId,
        }));
        // SET LOCATION AND TRANSPORT
        const multiPickLocation = [
          ...new Map(
            newVal.flatMap((item) => (item.multiPick ? item.multiPick.map((pick) => [
              pick.locationId,
              { id: pick.locationId, name: pick.name },
            ]) : [])),
          ).values(),
        ];

        const multiDropLocation = [
          ...new Map(
            newVal.flatMap((item) => (item.multiDrop ? item.multiDrop.map((drop) => [
              drop.locationId,
              { id: drop.locationId, name: drop.name },
            ]) : [])),
          ).values(),
        ];

        const destinationLocation = newVal.map((item) => ({
          name: item.destinationLocation,
          id: item.destinationLocationId,
        }));
        const originLocation = newVal.map((item) => ({
          name: item.originalLocation,
          id: item.originLocationId,
        }));
        this.itemsLocation = [...destinationLocation, ...originLocation, ...multiPickLocation, ...multiDropLocation];
        this.itemsVehicle = newVal.map((item) => ({
          name: item.transportName,
          id: item.transportTypeId,
        }));
      }
    },
  },
  data() {
    return {
      isLoadingDeleteRoute: [],
      isLoading: false,
      dialog: false,
      panel: 0,
      reFecthData: false,
      itemsVehicle: [],
      itemsLocation: [],
      pickupDate: null,
      isBackdate: false,
      item: {},
      form: {
        dateTimeTz: this.getDateTimeTz(),
        shipmentsGroupId: '',
        routes: [
          {
            shipmentsCargoId: null,
            pickupDate: '',
            serviceType: '',
            shipmentId: [],
            transportTypeId: '',
            originLocationId: '',
            multiPick: [],
            multiDrop: [],
            destinationLocationId: '',
          },
        ],
      },
      routeData: [], // from get api
      locationFilterItems: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      transportTypesFilterItems: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    filterDuplicateValues,
    getDateTimeTz,
    updateAutoCompleteItems(items, itemsName) {
      this[itemsName] = this.filterDuplicateValues([...this[itemsName], ...items]);
    },
    disabledForm(shipmentsCargoId) {
      const statusAllowed = ['waktu habis', 'pesanan dibuat', 'ditolak'];
      const findRoute = this.routeData.find((route) => route.shipmentsCargoId === shipmentsCargoId);
      if (findRoute) return !statusAllowed.includes(findRoute.status.toLowerCase());
      return false;
    },
    disabledDeleteRoute(indexRoute, shipmentsCargoId) {
      if (this.form.routes.length === 1) return true;
      if (this.routeData.length === 1 && indexRoute === 0) return true;
      const statusCanDelete = ['dibatalkan', 'kedaluwarsa'];
      const findRoute = this.routeData.find((route) => route.shipmentsCargoId === shipmentsCargoId) || {};
      if (findRoute.status && statusCanDelete.includes(findRoute.status.toLowerCase())) return false;
      return this.disabledForm(shipmentsCargoId);
    },
    addRoute() {
      this.form.routes.push({
        shipmentsCargoId: null,
        pickupDate: '',
        serviceType: '',
        shipmentId: [],
        transportTypeId: '',
        originLocationId: '',
        multiPick: [],
        multiDrop: [],
        destinationLocationId: '',
      });
      this.panel = this.form.routes.length - 1;
    },
    async handleDeleteRoute(indexRoute, shipmentsCargoId) {
      if (shipmentsCargoId) {
        this.$dialog.confirm({
          text: 'Apakah anda yakin akan menghapus rute ini?',
          title: this.$_strings.common.WARNING,
          persistent: true,
          actions: {
            false: this.$_strings.common.NO,
            true: this.$_strings.common.YES,
          },
        }).then(async (userRes) => {
          if (!userRes) return;
          try {
            this.isLoadingDeleteRoute.push(indexRoute);
            const shipmentsGroupId = this.item.groupId;
            await this.$_services.order.deleteRoute(shipmentsGroupId, shipmentsCargoId);
            this.$dialog.notify.success('Berhasil menghapus rute');
            this.form.routes.splice(indexRoute, 1);
            this.panel = this.form.routes.length - 1;
            this.reFecthData = true;
          } finally {
            this.isLoadingDeleteRoute.splice(this.isLoadingDeleteRoute.indexOf(indexRoute), 1);
          }
        });
        return;
      }
      this.form.routes.splice(indexRoute, 1);
      this.panel = this.form.routes.length - 1;
    },
    validateForm() {
      let isValid = true;
      this.$refs.form.forEach((form) => {
        const validating = form.validate();
        if (!validating) isValid = false;
      });
      if (!isValid) {
        this.$dialog.notify.warning('Mohon periksa kembali');
      }
      return isValid;
    },
    async submitRoute() {
      if (!this.validateForm()) return;
      try {
        this.isLoading = true;
        await this.$_services.order.saveRoute(this.form);
        this.$dialog.notify.success('Berhasil menyimpan rute');
        this.reFecthData = true;
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
