<template>
  <v-row class="mt-2">
    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="12" sm="4">
          <label>
            {{$_strings.order.SEND_DATE}}
            <span class="error--text d-inline">
              *
            </span>
          </label>
        </v-col>
        <v-col class="pb-0" cols="12" sm="4" md="3">
          <v-menu
            ref="menuPickupDate"
            v-model="menuPickupDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="caption"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                class="caption"
                :value="form.pickupDate && dateFormat(form.pickupDate)"
                placeholder="Tanggal"
                prepend-inner-icon="mdi-calendar"
                :rules="disabled ? [] : [v => !!v || $_strings.messages.validation.REQUIRED('Tanggal')]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.pickupDate"
              @input="menuPickupDate = false"
              :min="minDate()"
              @change="handleDateChanged"
              no-title
              scrollable
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="pb-0" cols="12" sm="4" md="3">
          <v-text-field
            ref="time"
            :rules="disabled ? [] : [($event) => rules.time($event)]"
            :value="form.pickupDate && timeFormat(form.pickupDate)"
            @input="setPickupDate"
            placeholder="Jam"
            v-mask="{'alias': 'time', rightAlign: false}"
            outlined
            dense
            prepend-inner-icon="mdi-clock-time-four-outline"
            class="caption"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="item.serviceType === 'LCL' || item.serviceType === 'FCL'" class="mt-0" align="center">
        <v-col cols="12" sm="4">
          <label>
            {{$_strings.order.SERVICE_TYPE}}
            <span class="error--text d-inline">
              *
            </span>
          </label>
        </v-col>
        <v-col class="pb-0" cols="12" sm="8" md="6">
          <v-select
            :items="itemsServiceType"
            @change="deleteMultipickAndMultidrop"
            v-model="form.serviceType"
            dense
            outlined
            :rules="disabled ? [] : [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.SERVICE_TYPE)]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-0" align="center">
        <v-col cols="12" sm="4">
          <label>
            {{$_strings.order.ORDER_NUMBER}}
            <span class="error--text d-inline">
              *
            </span>
          </label>
        </v-col>
        <v-col class="pb-0" cols="12" sm="8" md="6">
          <div class="d-flex justify-center align-items-center">
            <v-select
              item-value="id"
              item-text="orderNumber"
              v-model="tempForm.shipmentId"
              dense
              :items="itemsShipment"
              :rules="disabled ? [] : form.shipmentId.length > 0 ? [] : rules.shipments"
              outlined
            ></v-select>
            <v-icon
              v-if="itemsShipment.length && !disabled"
              @click="handleAddShipmentId"
              class="ml-3 mb-5"
              color="primary"
              :disabled="!tempForm.shipmentId"
              :class="tempForm.shipmentId && 'btn-plus-shipment'"
            >
              mdi-plus-circle
            </v-icon>
          </div>
          <div
            v-for="(shipmentId, shipmentIndex) in form.shipmentId"
            :key="shipmentIndex"
            class="text-select-hint">
            <span class="grey--text text--darken-1">
              {{getOrderNumber(shipmentId)}}
            </span>
            <v-icon
              v-if="!disabled"
              @click="removeShipmentId(shipmentIndex)"
              class="body-1 ml-3"
              color="red"
            >
              mdi-close-circle-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0" align="center">
        <v-col cols="12" sm="4">
          <label>
            {{$_strings.order.TYPE_VEHICLE}}
            <span class="error--text d-inline">
              *
            </span>
          </label>
        </v-col>
        <v-col class="pb-0" cols="12" sm="8" md="6">
          <common-auto-complete-items
            type="transportTypes"
            searchName="name"
            item-value="id"
            item-text="name"
            class="body-2 pt-2"
            dense
            outlined
            clearable
            v-model="form.transportTypeId"
            @updateItems="(items) => $emit('updateAutoCompleteItems', items, 'itemsVehicle')"
            :filter="transportTypesFilterItems"
            :items="itemsVehicle"
            :rules="disabled ? [] : [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.TYPE_VEHICLE)]"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0" align="center">
        <v-col cols="12" sm="4">
          <label>
            {{$_strings.order.ORIGIN}}
            <span class="error--text d-inline">
              *
            </span>
          </label>
        </v-col>
        <v-col class="pb-0" cols="12" sm="8" md="6">
          <common-auto-complete-items
            type="locations"
            searchName="name"
            item-value="id"
            item-text="name"
            dense
            outlined
            class="body-2 pt-2"
            clearable
            v-model="form.originLocationId"
            :rules="disabled ? [] : [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.ORIGIN)]"
            :filter="locationFilterItems"
            :items="itemsOrigin"
            @updateItems="(items) => $emit('updateAutoCompleteItems', items, 'itemsLocation')"
          />
        </v-col>
      </v-row>
      <v-row class="mt-2" align="center" v-if="form.serviceType !== 'LCL'">
        <v-col cols="12" sm="4">{{$_strings.order.MULTIPICK}}</v-col>
        <v-col cols="12" sm="8" md="6">
          <div class="mb-2 d-flex align-items-center">
            <common-auto-complete-items
              type="locations"
              searchName="name"
              item-value="id"
              item-text="name"
              dense
              outlined
              class="body-2 pt-2"
              clearable
              v-model="tempForm.multipick"
              :filter="locationFilterItems"
              :items="itemsLocation"
              hide-details
              @updateItems="(items) => $emit('updateAutoCompleteItems', items, 'itemsLocation')"
            />
            <v-icon
              v-if="!disabled"
              @click="handleAddMultipickToForm"
              class="ml-3"
              color="primary"
              :class="tempForm.multipick && 'btn-plus-shipment'"
              :disabled="!tempForm.multipick"
            >
              mdi-plus-circle
            </v-icon>
          </div>
          <div v-for="(pick, indexMultipick) in form.multiPick" :key="indexMultipick" class="text-select-hint">
            <span class="grey--text text--darken-1">
              {{indexMultipick + 1}} - {{ getPickLocationName(pick.locationId) }}
            </span>
            <v-icon
              v-if="!disabled"
              @click="removeMultipickFromForm(indexMultipick)"
              class="body-1"
              color="red"
            >
              mdi-close-circle-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2" align="center">
        <v-col cols="12" sm="4">
          <label>
            {{$_strings.order.DESTINATION}}
            <span class="error--text d-inline">
              *
            </span>
          </label>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <common-auto-complete-items
            type="locations"
            searchName="name"
            item-value="id"
            item-text="name"
            dense
            outlined
            class="body-2 pt-2"
            clearable
            v-model="form.destinationLocationId"
            :rules="disabled ? [] : [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.DESTINATION)]"
            :filter="locationFilterItems"
            :items="itemsDestination"
            @updateItems="(items) => $emit('updateAutoCompleteItems', items, 'itemsLocation')"
          />
        </v-col>
      </v-row>
      <v-row class="mt-2" align="center" v-if="form.serviceType !== 'LCL'">
        <v-col cols="12" sm="4">{{$_strings.order.MULTIDROP}}</v-col>
        <v-col cols="12" sm="8" md="6">
          <div class="mb-2 d-flex align-items-center">
            <common-auto-complete-items
              type="locations"
              searchName="name"
              item-value="id"
              item-text="name"
              dense
              outlined
              class="body-2 pt-2"
              clearable
              v-model="tempForm.multidrop"
              :filter="locationFilterItems"
              :items="itemsLocation"
              hide-details
              @updateItems="(items) => $emit('updateAutoCompleteItems', items, 'itemsLocation')"
            />
            <v-icon
              v-if="!disabled"
              @click="handleAddMultidropToForm"
              :class="tempForm.multidrop && 'btn-plus-shipment'"
              class="ml-3"
              color="primary"
              :disabled="!tempForm.multidrop"
            >
              mdi-plus-circle
            </v-icon>
          </div>
          <div v-for="(drop, indexMultidrop) in form.multiDrop" :key="indexMultidrop" class="text-select-hint">
            <span class="grey--text text--darken-1">
              {{indexMultidrop + 1}} - {{ getDropLocationName(drop.locationId) }}
            </span>
            <v-icon
              v-if="!disabled"
              @click="removeMultidropFromForm(indexMultidrop)"
              class="body-1"
              color="red"
            >
              mdi-close-circle-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4">
      <location-detail
        :form="form"
        :multipick="multipick"
        :multidrop="multidrop"
        :itemsLocation="itemsLocation"
        :itemsOrigin="itemsOrigin"
        :itemsDestination="itemsDestination"
      />
    </v-col>
  </v-row>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import LocationDetail from './LocationDetail.vue';

export default {
  components: {
    LocationDetail,
  },
  props: {
    pickupDate: {
      type: String,
      default: null,
    },
    isBackdate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDeleteRoute: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    locationFilterItems: {
      type: Object,
      default: () => {},
    },
    transportTypesFilterItems: {
      type: Object,
      default: () => {},
    },
    itemsLocation: {
      type: Array,
      default: () => [],
    },
    itemsVehicle: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuPickupDate: false,
      tempForm: {
        time: null,
        shipmentId: null,
        multipick: null,
        multidrop: null,
      },
      itemsServiceType: [
        {
          text: 'LCL',
          value: 'LCL',
        },
        {
          text: 'FCL',
          value: 'FCL',
        },
        {
          text: 'FCL BACKHAULING',
          value: 'FCL_BACKHAULING',
        },
      ],
      rules: {
        shipments: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('No Pesanan'),
        ],
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          if (this.canDeleteRoute && !this.isBackdate) {
            const pickupDate = dayjs(this.form.pickupDate).format('YYYY-MM-DD');
            const date = dayjs(`${pickupDate} ${value}`).format();
            const date1 = dayjs(date);
            const date2 = dayjs();
            const x = date1.diff(date2, 'hours', true);
            if (x >= 2) {
              return true;
            }
            return 'Minimal 2 jam dari waktu saat ini';
          }
          return true;
        },
      },
    };
  },
  computed: {
    shipments() {
      return this.item.subOrder.shipmentResponse.map((order) => ({
        id: order.id,
        orderNumber: order.orderNumber,
      }));
    },
    itemsShipment() {
      return this.item.subOrder.shipmentResponse.map((order) => ({
        id: order.id,
        orderNumber: order.orderNumber,
      })).filter((order) => !this.form.shipmentId.includes(order.id));
    },
    itemsOrigin() {
      const filterLoc = this.itemsLocation.filter((x) => x.id !== this.form.destinationLocationId);
      const locShipmentOrigin = this.item.subOrder.shipmentResponse.map((order) => ({
        id: order.originalLocation.locationId,
        name: order.originalLocation.name,
      }));
      return [...filterLoc, ...locShipmentOrigin];
    },
    multipick() {
      const filterShipmentByOrderId = this.item.subOrder.shipmentResponse.filter((shipment) => this.form.shipmentId.includes(shipment.id));
      if (filterShipmentByOrderId.length) {
        const multiPick = [];
        filterShipmentByOrderId.forEach((ship) => {
          ship.multiPick.forEach((m) => {
            multiPick.push(m);
          });
        });
        return multiPick;
      }
      return [];
    },
    multidrop() {
      const filterShipmentByOrderId = this.item.subOrder.shipmentResponse.filter((shipment) => this.form.shipmentId.includes(shipment.id));
      if (filterShipmentByOrderId.length) {
        const multiDrop = [];
        filterShipmentByOrderId.forEach((ship) => {
          ship.multiDrop.forEach((m) => {
            multiDrop.push(m);
          });
        });
        return multiDrop;
      }
      return [];
    },
    itemsMultipick() {
      return this.multipick.filter((pick) => !this.form.multiPick.find((fm) => fm.locationId === pick.locationId));
    },
    itemsDestination() {
      const filterLoc = this.itemsLocation.filter((x) => x.id !== this.form.originLocationId);
      const locShipmentDest = this.item.subOrder.shipmentResponse.map((order) => ({
        id: order.destinationLocation.locationId,
        name: order.destinationLocation.name,
      }));
      return [...filterLoc, ...locShipmentDest];
    },
    itemsMultidrop() {
      return this.multidrop.filter((drop) => !this.form.multiDrop.find((fm) => fm.locationId === drop.locationId));
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    minDate() {
      if (this.isBackdate && this.pickupDate) return this.pickupDate;
      if (this.form.pickupDate && (this.form.pickupDate < dayjs().format())) return this.form.pickupDate;
      return dayjs().format();
    },
    handleDateChanged() {
      const time = this.$refs.time.value || '00:00';
      this.setPickupDate(time);
      this.$refs.time.focus();
    },
    setPickupDate(time) {
      const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (this.form.pickupDate && timePattern.test(time)) {
        const date = dayjs(this.form.pickupDate).format('YYYY-MM-DD');
        const pickupDate = dayjs(`${date} ${time}`).format();
        this.form.pickupDate = pickupDate;
      }
    },
    handleAddShipmentId() {
      this.form.shipmentId.push(this.tempForm.shipmentId);
      // ADD TO FORM MULTIPICK AND MULTIDROP BY SHIPMENT ID
      this.addMultipickByShipmentId(this.tempForm.shipmentId);
      this.addMultidropByLocationId(this.tempForm.shipmentId);
      this.tempForm.shipmentId = null;
    },
    removeShipmentId(index) {
      const shipmentId = this.form.shipmentId[index];
      // REMOVE FROM FORM MULTIPICK AND MULTIDROP BY SHIPMENT ID
      this.removeMultipickByShipmentId(shipmentId);
      this.removeMultidropByShipmentId(shipmentId);
      this.form.shipmentId.splice(index, 1);
    },
    getOrderNumber(shipmentId) {
      const findOrder = this.shipments.find((shipment) => shipment.id === shipmentId);
      return (findOrder && findOrder.orderNumber) || '-';
    },
    getPickLocationName(locationId) {
      const findMultipick = this.itemsLocation.find((pick) => pick.id === locationId);
      return (findMultipick && findMultipick.name) || '-';
    },
    getDropLocationName(locationId) {
      const findMultidrop = this.itemsLocation.find((drop) => drop.id === locationId);
      return (findMultidrop && findMultidrop.name) || '-';
    },
    multiPickItemsByShipmentId(shipmentId) {
      const { shipmentResponse } = this.item.subOrder;
      const routeShipment = shipmentResponse.find((s) => s.id === shipmentId) || {};
      return routeShipment.multiPick || [];
    },
    multiDropItemsByShipmentId(shipmentId) {
      const { shipmentResponse } = this.item.subOrder;
      const routeShipment = shipmentResponse.find((s) => s.id === shipmentId) || {};
      return routeShipment.multiDrop || [];
    },
    addMultipickByShipmentId(shipmentId) {
      const multiPick = this.multiPickItemsByShipmentId(shipmentId);
      const sequenceNo = this.form.multiPick.length === 0 ? 1 : this.form.multiPick.length;
      multiPick.forEach((m, index) => {
        this.form.multiPick.push({ locationId: m.locationId, sequenceNo: sequenceNo + index });
      });
    },
    addMultidropByLocationId(shipmentId) {
      const multiDrop = this.multiDropItemsByShipmentId(shipmentId);
      const sequenceNo = this.form.multiDrop.length === 0 ? 1 : this.form.multiDrop.length;
      multiDrop.forEach((m, index) => {
        this.form.multiDrop.push({ locationId: m.locationId, sequenceNo: sequenceNo + index });
      });
    },
    removeMultipickByShipmentId(shipmentId) {
      const locationIds = this.multiPickItemsByShipmentId(shipmentId).map((m) => m.locationId);
      this.form.multiPick = this.form.multiPick.filter((f) => !locationIds.includes(f.locationId));
    },
    removeMultidropByShipmentId(shipmentId) {
      const locationIds = this.multiDropItemsByShipmentId(shipmentId).map((m) => m.locationId);
      this.form.multiDrop = this.form.multiDrop.filter((f) => !locationIds.includes(f.locationId));
    },
    handleAddMultipickToForm() {
      this.form.multiPick.push({ locationId: this.tempForm.multipick, sequenceNo: this.form.multiPick.length + 1 });
      this.tempForm.multipick = null;
    },
    removeMultipickFromForm(index) {
      this.form.multiPick.splice(index, 1);
      // SET SEQUENCE
      this.form.multiPick = this.form.multiPick.map((m, mIndex) => ({
        locationId: m.locationId,
        sequenceNo: mIndex + 1,
      }));
    },
    handleAddMultidropToForm() {
      this.form.multiDrop.push({ locationId: this.tempForm.multidrop, sequenceNo: this.form.multiDrop.length + 1 });
      this.tempForm.multidrop = null;
    },
    removeMultidropFromForm(index) {
      this.form.multiDrop.splice(index, 1);
      // SET SEQUENCE
      this.form.multiDrop = this.form.multiDrop.map((m, mIndex) => ({
        locationId: m.locationId,
        sequenceNo: mIndex + 1,
      }));
    },
    deleteMultipick() {
      this.form.multiPick = [];
    },
    deleteMultidrop() {
      this.form.multiDrop = [];
    },
    deleteMultipickAndMultidrop() {
      if (this.form.serviceType === 'LCL') {
        this.deleteMultipick();
        this.deleteMultidrop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn-plus-shipment {
    animation: ease-in 5s infinite reverse both running bounce;
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-10px);}
      60% {transform: translateY(-5px);}
    }
  }
  .text-select-hint {
    border: 1px solid #ddd;
    width: max-content + 1;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
</style>
